import {type AmenityCategory} from '#/cms/schemas/amenity-category.schema';
import {type Category} from '#/cms/schemas/category.schema';
import {usePlaceStore} from '#/features/store/placeStore';
import {type PepIconName} from '@calvium/pep-icons';
import dayjs, {type Dayjs} from 'dayjs';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';
import {useCallback, useMemo} from 'react';
import {z} from 'zod';
import {useMonthDateOptions, useTimeframeDateOptions} from './dates';
import {useRadiusOptions, type Radius} from './geo';
import {useDebouncedCallback} from 'use-debounce';

export const PLACE_SEARCH_PARAM_KEY = 'placeId';
export const RADIUS_SEARCH_PARAM_KEY = 'radius';
export const AMENITIES_SEARCH_PARAM_KEY = 'amenities';
export const CATEGORIES_SEARCH_PARAM_KEY = 'categories';
export const TEXT_SEARCH_PARAM_KEY = 'text';
export const DATE_SEARCH_PARAM_KEY = 'date';
export const MONTH_SEARCH_PARAM_KEY = 'month';
export const START_DATE_SEARCH_PARAM_KEY = 'startDate';
export const END_DATE_SEARCH_PARAM_KEY = 'endDate';
export const PANEL_VIEW_SEARCH_PARAM_KEY = 'panelView';

export const useURLFilterParams = () => {
  const searchParams = useSearchParams();
  const timeframeDateOptions = useTimeframeDateOptions();

  const urlFilterParams = useMemo(() => {
    const placeId = searchParams.get(PLACE_SEARCH_PARAM_KEY);
    const radius = searchParams.get(RADIUS_SEARCH_PARAM_KEY);
    const amenities = searchParams.getAll(AMENITIES_SEARCH_PARAM_KEY);
    const categories = searchParams.getAll(CATEGORIES_SEARCH_PARAM_KEY);
    const text = searchParams.get(TEXT_SEARCH_PARAM_KEY);
    const panelView = searchParams.get(PANEL_VIEW_SEARCH_PARAM_KEY) ?? 'grid';
    const month = searchParams.get(MONTH_SEARCH_PARAM_KEY);
    const date = searchParams.get(DATE_SEARCH_PARAM_KEY);
    let startDate: Dayjs | null = null;
    let endDate: Dayjs | null = null;

    if (date) {
      startDate = timeframeDateOptions.find(option => option.id === date)?.startDate ?? null;
      endDate = timeframeDateOptions.find(option => option.id === date)?.endDate ?? null;
    } else {
      const rawStartDate = searchParams.get(START_DATE_SEARCH_PARAM_KEY);
      if (rawStartDate) startDate = dayjs(rawStartDate);
      const rawEndDate = searchParams.get(END_DATE_SEARCH_PARAM_KEY);
      if (rawEndDate) endDate = dayjs(rawEndDate);
    }

    return {placeId, radius, categories, text, date, month, startDate, endDate, amenities, panelView};
  }, [timeframeDateOptions, searchParams]);

  return urlFilterParams;
};

export const useUpdateQueryParams = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const internalUpdateQueryParams = useCallback(
    (qs: Record<string, string | string[] | null>) => {
      const currentSearchParams = new URLSearchParams(Array.from(searchParams.entries()));

      for (const [key, value] of Object.entries(qs)) {
        currentSearchParams.delete(key);

        if (Array.isArray(value)) {
          for (const element of value) {
            currentSearchParams.append(key, element);
          }
        } else {
          if (!value) currentSearchParams.delete(key);
          else currentSearchParams.set(key, value);
        }
      }

      const search = currentSearchParams.toString();
      const query = search ? `?${search}` : '';
      // avoid page reload by changing the state but not doing a navigation action
      window.history.pushState(null, '', `${pathname}${query}`);
    },
    [pathname, searchParams]
  );
  const updateQueryParams = useDebouncedCallback(internalUpdateQueryParams, 100);
  return updateQueryParams;
};

export const useClearQueryParams = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const updateQueryParams = useUpdateQueryParams();
  const pathname = usePathname();

  const clearQueryParams = useCallback(
    (options?: {blacklistKeys?: string[]}) => {
      const blacklistKeys = options?.blacklistKeys;
      if (!blacklistKeys) {
        router.push(pathname);
      } else {
        const allSearchParamKeys = Array.from(searchParams.keys());
        const keysToClear = allSearchParamKeys.filter(key => !blacklistKeys.includes(key));
        const newSearchParams: Record<string, null> = keysToClear.reduce((acc, key) => ({...acc, [key]: null}), {});
        updateQueryParams(newSearchParams);
      }
    },
    [pathname, router, searchParams, updateQueryParams]
  );
  return clearQueryParams;
};

type ActiveFilter = {
  id: string;
  label: string;
  icon?: PepIconName;
  clear: () => void;
};

export const useActiveFilters = (categories: Category[], amenities: AmenityCategory[]) => {
  const params = useURLFilterParams();
  const updateQueryParams = useUpdateQueryParams();
  const radiusOptions = useRadiusOptions();
  const timeframeDateOptions = useTimeframeDateOptions();
  const monthDateOptions = useMonthDateOptions();
  const {placeMap} = usePlaceStore();

  const activeFilters = useMemo(() => {
    const result: ActiveFilter[] = [];

    // Dates
    if (params.date) {
      const date = timeframeDateOptions.find(option => option.id === params.date);
      if (date) {
        result.push({
          id: 'date',
          label: date.title,
          clear: () =>
            updateQueryParams({
              [DATE_SEARCH_PARAM_KEY]: null,
            }),
        });
      }
    } else if (params.month) {
      const month = monthDateOptions.find(option => option.id === params.month);
      if (month) {
        result.push({
          id: 'month',
          label: month.title,
          clear: () =>
            updateQueryParams({
              [MONTH_SEARCH_PARAM_KEY]: null,
              [START_DATE_SEARCH_PARAM_KEY]: null,
              [END_DATE_SEARCH_PARAM_KEY]: null,
            }),
        });
      }
    } else {
      if (params.startDate) {
        result.push({
          id: 'startDate',
          label: `From ${params.startDate.format('YYYY-MM-DD')}`,
          clear: () =>
            updateQueryParams({
              [START_DATE_SEARCH_PARAM_KEY]: null,
            }),
        });
      }
      if (params.endDate) {
        result.push({
          id: 'endDate',
          label: `To ${params.endDate.format('YYYY-MM-DD')}`,
          clear: () =>
            updateQueryParams({
              [END_DATE_SEARCH_PARAM_KEY]: null,
            }),
        });
      }
    }

    // Place
    if (params.placeId) {
      const place = placeMap.get(params.placeId);
      if (place) {
        const radius = radiusOptions[params.radius as Radius];
        result.push({
          id: 'place',
          label: `${place.title ?? place.id}${radius ? ` (${radius.title})` : ''}`,
          clear: () =>
            updateQueryParams({
              [PLACE_SEARCH_PARAM_KEY]: null,
              [RADIUS_SEARCH_PARAM_KEY]: null,
            }),
        });
      }
    }

    // Categories
    if (params.categories) {
      const activeCategories = params.categories
        .map(categoryId => categories.find(c => c.id === categoryId)!)
        .filter(Boolean);
      for (const category of activeCategories) {
        result.push({
          id: `category-${category.id}`,
          label: category.title ?? category.id,
          icon: category.pepIcon,
          clear: () =>
            updateQueryParams({
              [CATEGORIES_SEARCH_PARAM_KEY]: params.categories.filter(cId => cId !== category.id),
            }),
        });
      }
    }

    // Amenities
    if (params.amenities) {
      const activeAmenities = params.amenities
        .map(amenityId => amenities.find(a => a.id === amenityId)!)
        .filter(Boolean);
      for (const amenity of activeAmenities) {
        result.push({
          id: `amenity-${amenity.id}`,
          label: amenity.title ?? amenity.id,
          icon: amenity.pepIcon,
          clear: () =>
            updateQueryParams({
              [AMENITIES_SEARCH_PARAM_KEY]: params.amenities.filter(aId => aId !== amenity.id),
            }),
        });
      }
    }

    // Text Search
    if (params.text) {
      result.push({
        id: 'text',
        label: params.text,
        clear: () => updateQueryParams({[TEXT_SEARCH_PARAM_KEY]: null}),
      });
    }

    return result;
  }, [
    amenities,
    categories,
    monthDateOptions,
    params.amenities,
    params.categories,
    params.date,
    params.endDate,
    params.month,
    params.placeId,
    params.radius,
    params.startDate,
    params.text,
    placeMap,
    radiusOptions,
    timeframeDateOptions,
    updateQueryParams,
  ]);

  return activeFilters;
};

export const usePanelView = () => {
  const {panelView: _panelView} = useURLFilterParams();
  const updateQueryParams = useUpdateQueryParams();
  const panelViewsSchema = z.literal('grid').or(z.literal('map'));
  type PanelView = z.infer<typeof panelViewsSchema>;
  const {success} = panelViewsSchema.safeParse(_panelView);
  const panelView = (success ? _panelView : 'grid') as PanelView;

  const setPanelView = useCallback(
    (panelView: PanelView) => updateQueryParams({[PANEL_VIEW_SEARCH_PARAM_KEY]: panelView}),
    [updateQueryParams]
  );

  const togglePanelView = useCallback(
    () => setPanelView(panelView === 'grid' ? 'map' : 'grid'),
    [panelView, setPanelView]
  );

  return {
    panelView,
    setPanelView,
    togglePanelView,
  };
};

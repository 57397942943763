import {type Option} from '#/features/components/Select';
import {useUiStrings} from '#/features/store/uiStrings';
import {getBoundsOfDistance} from 'geolib';
import {useMemo} from 'react';

const milesToMeters = (miles: number) => miles * 1609.344;

export enum Radius {
  '2Miles' = '2miles',
  '5Miles' = '5miles',
  '10Miles' = '10miles',
  '20Miles' = '20miles',
  '30Miles' = '30miles',
  '50Miles' = '50miles',
}
export type RadiusOption = Option & {
  id: Radius;
  radius: number; // In meters
};

/**
 * Hook for returning radius options. The titles are localized so need to come from the ui strings.
 */
export const useRadiusOptions = () => {
  const uiStrings = useUiStrings();
  const radiusOptions: Record<Radius, RadiusOption> = useMemo(
    () => ({
      [Radius['2Miles']]: {
        id: Radius['2Miles'],
        title: uiStrings.general.radiusOption2Miles,
        radius: milesToMeters(2),
      },
      [Radius['5Miles']]: {
        id: Radius['5Miles'],
        title: uiStrings.general.radiusOption5Miles,
        radius: milesToMeters(5),
      },
      [Radius['10Miles']]: {
        id: Radius['10Miles'],
        title: uiStrings.general.radiusOption10Miles,
        radius: milesToMeters(10),
      },
      [Radius['20Miles']]: {
        id: Radius['20Miles'],
        title: uiStrings.general.radiusOption20Miles,
        radius: milesToMeters(20),
      },
      [Radius['30Miles']]: {
        id: Radius['30Miles'],
        title: uiStrings.general.radiusOption30Miles,
        radius: milesToMeters(30),
      },
      [Radius['50Miles']]: {
        id: Radius['50Miles'],
        title: uiStrings.general.radiusOption50Miles,
        radius: milesToMeters(50),
      },
    }),
    [uiStrings]
  );

  return radiusOptions;
};

type LatLng = {
  longitude: number;
  latitude: number;
};

/**
 * Converts a LatLng object to a Google LatLngLiteral object
 */
export const latLngToGoogleLatLngLiteral = (latLng: LatLng): google.maps.LatLngLiteral => {
  return {
    lat: latLng.latitude,
    lng: latLng.longitude,
  };
};

/**
 * Converts a Google LatLngLiteral object to a LatLng object
 */
export const googleLatLngLiteralToLatLng = (latLngLiteral: google.maps.LatLngLiteral): LatLng => {
  return {
    latitude: latLngLiteral.lat,
    longitude: latLngLiteral.lng,
  };
};

export type SearchRadius = {
  radius: number;
  title: string;
  center: google.maps.LatLngLiteral;
};

/**
 * Converts a SearchRadius object to a LatLngBoundsLiteral object
 */
export const searchRadiusToLatLngBoundsLiteral = (
  searchRadius: SearchRadius
): google.maps.LatLngBoundsLiteral | undefined => {
  const {radius, center} = searchRadius;
  const bounds = getBoundsOfDistance(center, radius);
  const east = bounds[0]?.longitude;
  const north = bounds[0]?.latitude;
  const south = bounds[1]?.latitude;
  const west = bounds[1]?.longitude;
  if (!!east && !!north && !!south && !!west) {
    return {
      east,
      north,
      south,
      west,
    };
  }
  return undefined;
};

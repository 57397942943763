import {useUiStrings} from '#/features/store/uiStrings';
import dayjs, {type Dayjs} from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import {useMemo} from 'react';

dayjs.extend(localeData);

export type DateOption = {
  id: string;
  title: string;
  startDate: Dayjs;
  endDate: Dayjs;
};

/**
 * Hook for returning timeframe date options.
 * The titles are localized so need to come from the ui strings.
 */
export const useTimeframeDateOptions = () => {
  const uiStrings = useUiStrings();
  const timeframeDateOptions: DateOption[] = useMemo(
    () => [
      {
        id: 'today',
        title: uiStrings.general.timeframeNames.today,
        startDate: dayjs().startOf('day'),
        endDate: dayjs().endOf('day'),
      },
      {
        id: 'this-weekend',
        title: uiStrings.general.timeframeNames.thisWeekend,
        startDate: dayjs().day(6).startOf('day'),
        endDate: dayjs().day(7).endOf('day'),
      },
      {
        id: 'tomorrow',
        title: uiStrings.general.timeframeNames.tomorrow,
        startDate: dayjs().add(1, 'day').startOf('day'),
        endDate: dayjs().add(1, 'day').endOf('day'),
      },
      {
        id: 'next-weekend',
        title: uiStrings.general.timeframeNames.nextWeekend,
        startDate: dayjs().day(13).startOf('day'),
        endDate: dayjs().day(14).endOf('day'),
      },
    ],
    [uiStrings]
  );
  return timeframeDateOptions;
};

/**
 * Hook for returning month date options.
 * The titles are localized so need to come from the ui strings.
 */
export const useMonthDateOptions = () => {
  const uiStrings = useUiStrings();
  const monthOptions = useMemo(() => {
    const monthKeys = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ] as const;
    const options: DateOption[] = [];
    const now = dayjs();
    const currentMonth = now.month();
    for (let i = 0; i < monthKeys.length; i++) {
      const monthKey = monthKeys[(currentMonth + i) % monthKeys.length]!;
      const month = now.add(i, 'months');
      options.push({
        id: monthKey,
        title: uiStrings.general.monthNames[monthKey],
        startDate: month.startOf('month'),
        endDate: month.endOf('month'),
      });
    }
    return options;
  }, [uiStrings]);

  return monthOptions;
};

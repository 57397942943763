'use client';

import dayjs, {type Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';

type SingleDayBadgeContentProps = {
  startDate: Dayjs;
};

const SingleDayBadgeContent = (props: SingleDayBadgeContentProps) => {
  const {startDate} = props;
  return (
    <p aria-hidden={true} className="m-0 text-center leading-3 tracking-widest" suppressHydrationWarning>
      <span className="block text-lg font-bold leading-4" suppressHydrationWarning={true}>
        {startDate.date()}
      </span>
      <span className="block text-xs leading-4" suppressHydrationWarning={true}>
        {startDate.format('MMM').toUpperCase()}
      </span>
    </p>
  );
};
type MultiDayBadgeContentProps = {
  startDate: Dayjs;
  endDate: Dayjs;
};

const MultiDayBadgeContent = (props: MultiDayBadgeContentProps) => {
  const {startDate, endDate} = props;
  return (
    <div aria-hidden={true} className="m-0 text-center text-xxs leading-3">
      <p>
        {startDate.date()} {startDate.format('MMM').toUpperCase()}
      </p>
      <div className="mx-auto my-0.5 w-1/4 border-b" />
      <p>
        {endDate.date()} {endDate.format('MMM').toUpperCase()}
      </p>
    </div>
  );
};

type Props = {
  // Start date in ISO 8601 format (as accepted by https://day.js.org/docs/en/parse/string)
  startDate: string;
  // Optional end date in ISO 8601 format (as accepted by https://day.js.org/docs/en/parse/string)
  endDate?: string;
};

export const DateBadge = (props: Props) => {
  const {startDate, endDate} = props;
  const [clientTimeZone, setClientTimeZone] = useState<string>('Europe/London');
  const start = dayjs(startDate).tz(clientTimeZone);
  const end = !!endDate ? dayjs(endDate).tz(clientTimeZone) : null;
  const multiDay = !!end && !start.startOf('day').isSame(end.startOf('day'));
  const ariaLabel = multiDay ? `${start.format('DD MMMM')} to ${end?.format('DD MMMM')}` : start.format('DD MMMM');

  useEffect(() => {
    // When rendered in client, set the client timezone
    setClientTimeZone(dayjs.tz.guess());
  }, []);

  if (!start.isValid()) return null;
  if (!!end && !end?.isValid()) return null;

  return (
    <div
      aria-label={ariaLabel}
      className="flex h-12 w-12 min-w-12 flex-col items-center justify-center rounded-xl bg-primary text-light group-hover:bg-secondary"
      suppressHydrationWarning
    >
      {multiDay && <MultiDayBadgeContent startDate={start} endDate={end} />}
      {!multiDay && <SingleDayBadgeContent startDate={start} />}
    </div>
  );
};
